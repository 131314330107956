<template>
  <footer class="bg-gray-800" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div
      class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 justify-between"
    >
      <div class="pb-8 xl:grid xl:grid-cols-2 xl:gap-8">
        <div class="grid grid-cols-2 gap-8 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div v-for="nav in navigation" :key="nav.name">
              <h3
                class="text-sm font-semibold text-gray-400 tracking-wider uppercase"
              >
                {{ nav.name }}
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li v-for="item in nav.content" :key="item.name">
                  <a
                    :href="item.href"
                    target="_blank"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between"
      >
        <div class="flex space-x-6 md:order-2">
          <a
            v-for="network in social"
            :key="network.name"
            :href="network.href"
            target="_blank"
            class="text-gray-400 hover:text-gray-300"
          >
            <span class="sr-only">{{ network.name }}</span>
            <component :is="network.icon" class="h-6 w-6" aria-hidden="true" />
          </a>
        </div>
        <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
          Made with ❤️ by Southborough Residents
          <!--Anders Hokinson-->
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { defineComponent, h } from "vue";

const navigation = [
  {
    name: "History",
    content: [
      {
        name: "MACRIS Database Entry",
        href: "https://mhc-macris.net/#!/details?mhcid=SBR.46",
      },
    ],
  },
];
const social = [
  {
    name: "Facebook",
    href: "https://facebook.com/profile.php?id=171258912998110",
    icon: defineComponent({
      render: () =>
        h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
          h("path", {
            "fill-rule": "evenodd",
            d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
            "clip-rule": "evenodd",
          }),
        ]),
    }),
  },
];
</script>
