<template>
  <Popover class="relative bg-white z-10">
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="flex justify-between items-center md:justify-start md:space-x-10"
      >
        <div class="flex justify-center lg:w-0 lg:flex-1">
          <a href="/">
            <span class="sr-only" />
            <img
              class="h-32 w-auto"
              src="https://static.southboroughcommunityhouse.org/community-house/img/header.png"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"
      >
        <div class="flex justify-start lg:w-0 lg:flex-1"></div>
        <div class="-mr-2 -my-2 md:hidden">
          <PopoverButton
            class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
          >
            <span class="sr-only" />
            <MenuIcon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup
          v-for="nav in navigation"
          :key="nav.name"
          as="nav"
          class="hidden md:flex space-x-10"
        >
          <Popover v-if="nav.content" v-slot="{ open }" class="relative">
            <PopoverButton
              :class="[
                open ? 'text-gray-900' : 'text-gray-500',
                'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none',
              ]"
            >
              <span>{{ nav.name }}</span>
              <ChevronDownIcon
                :class="[
                  open ? 'text-gray-600' : 'text-gray-400',
                  'ml-2 h-5 w-5 group-hover:text-gray-500',
                ]"
                aria-hidden="true"
              />
            </PopoverButton>

            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 translate-y-1"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
            >
              <PopoverPanel
                class="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
              >
                <div
                  class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
                >
                  <div
                    v-for="item in nav.content"
                    :key="item.name"
                    class="relative grid gap-6 bg-white px-5 py-3 sm:gap-6 sm:p-3"
                  >
                    <a
                      :key="item.name"
                      :href="item.href"
                      class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <component
                        :is="item.icon"
                        class="flex-shrink-0 h-6 w-6 text-gray-600"
                        aria-hidden="true"
                      />
                      <div class="ml-4">
                        <p class="text-base font-medium text-gray-900">
                          {{ item.name }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ item.description }}
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    v-if="nav.actions"
                    class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-4"
                  >
                    <div v-for="action in nav.actions" :key="action.name">
                      <div class="flow-root">
                        <a
                          :href="action.href"
                          class="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                        >
                          <component
                            :is="action.icon"
                            class="flex-shrink-0 h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                          <span class="ml-3">{{ action.name }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
          <a
            v-else
            :href="nav.href"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            {{ nav.name }}
          </a>
        </PopoverGroup>
        <div
          class="hidden md:flex items-center justify-end md:flex-1 lg:w-0"
        ></div>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        focus
        class="absolute top-25 inset-x-0 p-2 transition transform origin-top-right md:hidden"
      >
        <div
          class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50"
        >
          <div
            v-for="nav in navigation"
            :key="nav.name"
            class="py-3 px-5 space-y-3"
          >
            <div
              v-if="nav.content"
              class="text-base font-medium text-gray-900 hover:text-gray-700"
            >
              {{ nav.name }}
              <div class="grid grid-cols-2 gap-y-4 gap-x-8">
                <a
                  v-for="item in nav.content"
                  :key="item.name"
                  :href="item.href"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <component
                    :is="item.icon"
                    class="flex-shrink-0 h-6 w-6 text-gray-600"
                    aria-hidden="true"
                  />
                  <span class="ml-3 text-base font-medium text-gray-900">
                    {{ item.name }}
                  </span>
                </a>
              </div>
            </div>
            <div
              v-else
              class="text-base font-medium text-gray-900 hover:text-gray-700"
            >
              <a
                :href="nav.href"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                {{ nav.name }}
              </a>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import {
  ArchiveIcon,
  CalendarIcon,
  ClockIcon,
  HomeIcon,
  LibraryIcon,
  MenuIcon,
  PhoneIcon,
  TicketIcon,
} from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";

const navigation = [
  {
    name: "History",
    content: [
      {
        name: "Community House",
        description: "History of the Community House",
        href: "/history/house",
        icon: LibraryIcon,
      },
      {
        name: "C.F. Choate Jr.",
        description: "Biography of C.F. Choate Jr.",
        href: "/history/choate",
        icon: ArchiveIcon,
      },
    ],
  },
  {
    name: "About",
    content: [
      {
        name: "House",
        description: "Community House Pictures",
        href: "/about/house",
        icon: HomeIcon,
      },
      {
        name: "Events",
        description: "Sample Event Pictures",
        href: "/about/events",
        icon: TicketIcon,
      },
    ],
  },
  {
    name: "News",
    content: [
      {
        name: "100th Anniversary",
        description: "Buy a Brick",
        href: "/news/anniversary",
        icon: CalendarIcon,
      },
      {
        name: "Yearly Flyer",
        description: "",
        href: "/news/flyer",
        icon: ClockIcon,
      },
    ],
  },
  {
    name: "Contact",
    description: "For Availability and Reservations",
    href: "/contact",
    icon: PhoneIcon,
  },
];
</script>
